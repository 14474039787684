@use 'abstracts/variables';
@use 'abstracts/colors';

.form-control-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1d456827;
  border-radius: 0;
  font-size: map-get(variables.$font-size, 'small');
  outline: none;
  padding: 8px 0;
  width: 100%;

  &:focus,
  &.focused {
    border-bottom-color: map-get(colors.$color, 'secondary');
  }

  &:active {
    outline: none;
  }

  &.has-error {
    border-bottom-color: map-get(colors.$color, 'error');
  }
}

app-multi-autocomplete.form-control-input {
  padding: 4px 0;
}

mat-form-field.form-control-input .mat-form-field-wrapper {
  padding: 0 !important; // stylelint-disable-line declaration-no-important

  .mat-form-field-flex {
    align-items: center !important; // stylelint-disable-line declaration-no-important
  }

  .mat-form-field-infix {
    border: 0 !important; // stylelint-disable-line declaration-no-important
  }

  .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
    height: 1.75em !important; // stylelint-disable-line declaration-no-important
    width: 1.75em !important; // stylelint-disable-line declaration-no-important
  }
}
