@use 'abstracts/variables';
@use 'abstracts/colors';

.mat-menu-panel {
  border-radius: 2px !important; /* stylelint-disable-line declaration-no-important */
  box-shadow: 0 0 40px rgba(0, 0, 0, 8%);
  max-width: unset !important; /* stylelint-disable-line declaration-no-important */
}

/* stylelint-disable-next-line */
.context-menu_backdrop + .cdk-overlay-connected-position-bounding-box .mat-menu-panel {
  border: 1px solid rgba(29, 69, 104, 24%);
  box-shadow: 0 0 8px rgba(29, 69, 104, 12%);
}

.mat-menu-content {
  background-color: map-get(colors.$color, 'background-04');

  .mat-menu-item {
    background-color: map-get(colors.$color, 'background-04');
  }
}
