@use 'abstracts/variables';
@use 'abstracts/colors';

/* stylelint-disable declaration-no-important */

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-label {
    &.mat-tab-label-active {
      color: map-get(colors.$color, 'primary');

      &::after {
        opacity: 1;
        transition: opacity 0.2s ease-in 0.4s;
      }
    }

    &:not(.mat-tab-label-active) .mat-tab-label-content:hover {
      color: map-get(colors.$color, 'secondary-light');
    }
  }

  .mat-mdc-tab {
    background-color: transparent !important;
    color: map-get(colors.$color, 'text-primary');
    height: auto !important;
    margin: 0 16px;
    min-width: auto !important;
    opacity: 1 !important;
    overflow: visible;
    padding: 12px 0 !important;

    &:not(:last-of-type)::before {
      background-color: map-get(colors.$color, 'divider');
      bottom: 4px;
      content: '';
      position: absolute;
      right: -16px;
      top: 4px;
      width: 1px;
    }

    &::after {
      bottom: -12px;
      box-shadow: 0 -3px 9px 0 rgba(map-get(colors.$color, 'primary'), 0.4);
      content: '';
      height: 12px;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }

  .mdc-tab__text-label {
    font-size: map-get(variables.$font-size, 'reduced');
    font-weight: map-get(variables.$font-weight, 'medium');

    .count {
      font-weight: map-get(variables.$font-weight, 'bold');
      margin-left: 4px;
    }
  }

  .mat-ink-bar {
    background-color: map-get(colors.$color, 'primary') !important;
  }
}
/* stylelint-enable declaration-no-important */
