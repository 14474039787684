@use 'abstracts/variables';
@use 'abstracts/colors';

ngx-material-timepicker-container {
  /* stylelint-disable-next-line declaration-no-important */
  --primary-font-family: 'Ubuntu' !important;
  /* stylelint-disable-next-line declaration-no-important */
  --button-color: #{map-get(colors.$color, 'primary')} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --dial-background-color: #{map-get(colors.$color, 'primary')} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --dial-editable-active-color: #{map-get(colors.$color, 'primary')} !important;
  /* stylelint-disable-next-line declaration-no-important */
  --clock-hand-color: #{map-get(colors.$color, 'primary')} !important;
}

.timepicker-backdrop-overlay {
  z-index: 1000 !important; // stylelint-disable-line declaration-no-important
}

.timepicker-overlay {
  z-index: 1000 !important; // stylelint-disable-line declaration-no-important
}
