/* stylelint-disable declaration-no-important */

@use 'abstracts/variables';
@use 'abstracts/colors';

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}

.mat-checkbox-frame {
  border: 1px solid map-get(colors.$color, 'divider') !important;
  border-radius: 2px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: map-get(colors.$color, 'secondary') !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: map-get(colors.$color, 'secondary') !important;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  background-color: map-get(colors.$color, 'divider') !important;
  border: 1px solid map-get(colors.$color, 'divider') !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: map-get(colors.$color, 'divider') !important;
}
