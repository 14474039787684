@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/colors';

$text-link-types: (
  'light': (
    'color': #fff,
    'hoverColor': map-get(colors.$color, 'background-01'),
  ),
  'dark': (
    'color': map-get(colors.$color, 'secondary'),
    'hoverColor': map-get(colors.$color, 'secondary-light'),
  ),
);

@each $text-link-type, $color-type in $text-link-types {
  $color: map-get($color-type, 'color') !important;
  $hover-color: map-get($color-type, 'hoverColor');

  .text-link {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    outline: none;

    &.sm-#{$text-link-type} {
      @include mixins.font(map-get(variables.$font-size, 'micro'), map-get(variables.$font-weight, 'medium'), $color);

      &:hover {
        color: $hover-color;
      }
    }

    &.md-#{$text-link-type} {
      @include mixins.font(map-get(variables.$font-size, 'reduced'), map-get(variables.$font-weight, 'medium'), $color);

      &:hover {
        color: $hover-color;
      }
    }

    &.disabled {
      color: map-get(colors.$color, 'text-tertiary') !important;
      cursor: default;
      pointer-events: none;
    }
  }
}

a.text-link {
  text-decoration: none;
}

.tecex-hover:hover {
  border: 1px solid red;
  color: map-get(colors.$color, 'primary');
}
