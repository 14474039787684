@use 'abstracts/units';

$global-font-size: 100%;
$font-size: (
  'increased': units.rem-calc(22),
  'normal': units.rem-calc(18),
  'reduced': units.rem-calc(16),
  'small': units.rem-calc(14),
  'tiny': units.rem-calc(12),
  'micro': units.rem-calc(11),
);
$font-weight: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'bold': 700,
);
$line-height: (
  'increased': normal,
  'normal': 21 / 18,
  'reduced': 18 / 16,
  'small': 18 / 14,
  'tiny': 17 / 12,
  'micro': 17 / 12,
);
$typography: (
  'increased-regular': (
    'font-size': map-get($font-size, 'increased'),
    'font-weight': map-get($font-weight, 'regular'),
    'line-height': map-get($line-height, 'increased'),
  ),
  'increased-bold': (
    'font-size': map-get($font-size, 'increased'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'increased'),
  ),
  'normal-light': (
    'font-size': map-get($font-size, 'normal'),
    'font-weight': map-get($font-weight, 'light'),
    'line-height': map-get($line-height, 'normal'),
  ),
  'normal-bold': (
    'font-size': map-get($font-size, 'normal'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'normal'),
  ),
  'reduced-light': (
    'font-size': map-get($font-size, 'reduced'),
    'font-weight': map-get($font-weight, 'light'),
    'line-height': map-get($line-height, 'reduced'),
  ),
  'reduced-regular': (
    'font-size': map-get($font-size, 'reduced'),
    'font-weight': map-get($font-weight, 'regular'),
    'line-height': map-get($line-height, 'reduced'),
  ),
  'reduced-medium': (
    'font-size': map-get($font-size, 'reduced'),
    'font-weight': map-get($font-weight, 'medium'),
    'line-height': map-get($line-height, 'reduced'),
  ),
  'reduced-bold': (
    'font-size': map-get($font-size, 'reduced'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'reduced'),
  ),
  'small-light': (
    'font-size': map-get($font-size, 'small'),
    'font-weight': map-get($font-weight, 'light'),
    'line-height': map-get($line-height, 'small'),
  ),
  'small-regular': (
    'font-size': map-get($font-size, 'small'),
    'font-weight': map-get($font-weight, 'regular'),
    'line-height': map-get($line-height, 'small'),
  ),
  'small-bold': (
    'font-size': map-get($font-size, 'small'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'small'),
  ),
  'tiny-light': (
    'font-size': map-get($font-size, 'tiny'),
    'font-weight': map-get($font-weight, 'light'),
    'line-height': map-get($line-height, 'tiny'),
  ),
  'tiny-regular': (
    'font-size': map-get($font-size, 'tiny'),
    'font-weight': map-get($font-weight, 'regular'),
    'line-height': map-get($line-height, 'tiny'),
  ),
  'tiny-medium': (
    'font-size': map-get($font-size, 'tiny'),
    'font-weight': map-get($font-weight, 'medium'),
    'line-height': map-get($line-height, 'tiny'),
  ),
  'tiny-bold': (
    'font-size': map-get($font-size, 'tiny'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'tiny'),
  ),
  'micro-light': (
    'font-size': map-get($font-size, 'micro'),
    'font-weight': map-get($font-weight, 'light'),
    'line-height': map-get($line-height, 'micro'),
  ),
  'micro-regular': (
    'font-size': map-get($font-size, 'micro'),
    'font-weight': map-get($font-weight, 'regular'),
    'line-height': map-get($line-height, 'micro'),
  ),
  'micro-bold': (
    'font-size': map-get($font-size, 'micro'),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': map-get($line-height, 'micro'),
  ),
);
$header: (
  'h1': (
    'font-size': units.rem-calc(52),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': 60 / 52,
  ),
  'h2': (
    'font-size': units.rem-calc(43),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': 49 / 43,
  ),
  'h3': (
    'font-size': units.rem-calc(18),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': 21 / 18,
  ),
  'h4': (
    'font-size': units.rem-calc(16),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': 18 / 16,
  ),
  'h5': (
    'font-size': units.rem-calc(22),
    'font-weight': map-get($font-weight, 'bold'),
    'line-height': 25 / 22,
  ),
);
