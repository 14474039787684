@use 'abstracts/variables';
@use 'abstracts/colors';

.pagination {
  display: flex;
  justify-content: center;

  .ngx-pagination {
    color: map-get(colors.$color, 'text-primary');
    font-size: map-get(variables.$font-size, 'reduced');
    line-height: map-get(variables.$line-height, 'reduced');
    padding-left: 0;

    .pagination-previous a::before,
    .pagination-previous.disabled::before,
    .pagination-next a::after,
    .pagination-next.disabled::after {
      display: none;
    }

    .disabled {
      color: map-get(colors.$color, 'text-tertiary');
    }

    li {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    li.current,
    a {
      padding: 0.5rem;
    }

    a:hover {
      background-color: transparent;
      color: map-get(colors.$color, 'secondary-light');
    }

    .current {
      background-color: transparent;
      color: map-get(colors.$color, 'primary');
      position: relative;

      &::after {
        /* stylelint-disable-next-line */
        background-color: currentColor;
        bottom: 0;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
