@use 'abstracts/variables';
@use 'abstracts/colors';

* {
  box-sizing: border-box;
}

html {
  background-color: map-get(colors.$color, 'background-02');
  height: 100%;
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;

  &.bg-white {
    background-color: map-get(colors.$color, 'background-04');
  }
}

body {
  height: 0;
  min-height: 100%;
}

svg-icon {
  line-height: 0;
}

// For adding the third party name css from base :

.company-third-party-name {
  color: map-get(colors.$color, 'message-modal-company-title'); // stylelint-disable-line
  font-weight: 100; // stylelint-disable-line
  font-size: 13px; // stylelint-disable-line
}

input[type='number'] {
  -moz-appearance: textfield; // stylelint-disable-line

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // stylelint-disable-line
    margin: 0;
  }
}

.max-available-width {
  max-width: 100%;
  min-width: 0;
}

.text-input-appearance {
  -moz-appearance: textfield; // stylelint-disable-line

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // stylelint-disable-line
    margin: 0;
  }
}

:not(body):not(html):not([contenteditable]) {
  /* Scrollbar */

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: map-get(colors.$color, 'divider');
    background-clip: padding-box;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: map-get(colors.$color, 'background-05');
    background-clip: padding-box;
    border-bottom: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
  }
}

.cdk-global-scrollblock-prevent-offset {
  top: 0 !important; // stylelint-disable-line
  position: initial !important; // stylelint-disable-line
  overflow: hidden !important; // stylelint-disable-line
}
