@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/colors';
@use 'abstracts/bootstrap';
@use 'abstracts/units';

.dialog-backdrop {
  background-color: map-get(colors.$color, 'dialog-backdrop-background');
}

.dialog-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.dialog-title {
  color: map-get(colors.$color, 'background-05');
  font-size: units.rem-calc(22);
  margin-bottom: 2.5rem;
}

.dialog-content {
  flex: 1;
  overflow: auto;
  padding: 2.5rem;
}

.dialog-footer {
  @include mixins.crop-edge(bootstrap.$grid-gutter-width, #f3f4f4);

  @include bootstrap.media-breakpoint-up(md) {
    @include mixins.crop-edge(31px, #f3f4f4);
  }

  .button-container {
    padding: 16px 0;
  }

  .cross {
    background-color: map-get(colors.$color, 'primary');
    height: 2px;
    left: calc(bootstrap.$grid-gutter-width - 12px);
    position: absolute;
    right: 0;
    top: -1px;

    @include bootstrap.media-breakpoint-up(md) {
      left: 19px;
    }

    &::before {
      background-color: map-get(colors.$color, 'primary');
      content: '';
      height: 26px;
      left: 11px;
      position: absolute;
      top: -12px;
      width: 2px;
    }
  }
}
