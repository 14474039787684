@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/units';
@use 'abstracts/bootstrap';

.card-container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 8%);
  padding: bootstrap.$grid-gutter-width;

  @include bootstrap.media-breakpoint-up(md) {
    padding: 40px;
  }

  .card-header {
    align-items: center;
    color: map-get(colors.$color, 'background-05');
    display: flex;
    font-size: units.rem-calc(22);
    font-weight: bold;
    line-height: units.rem-calc(25);
  }
}
