@use 'abstracts/variables';

@font-face {
  font-display: auto;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: map-get(variables.$font-weight, 'light');
  src: url('^assets/fonts/ubuntu-light.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: map-get(variables.$font-weight, 'regular');
  src: url('^assets/fonts/ubuntu-regular.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: map-get(variables.$font-weight, 'medium');
  src: url('^assets/fonts/ubuntu-medium.woff2') format('woff2');
}

@font-face {
  font-display: auto;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: map-get(variables.$font-weight, 'bold');
  src: url('^assets/fonts/ubuntu-bold.woff2') format('woff2');
}
