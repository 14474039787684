@use 'sass:math';

.bottom-bar {
  box-shadow: 0 0 8px rgba(0, 0, 0, 50%);
  padding: math.div($grid-gutter-width, 2) $grid-gutter-width;
  background: white;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
