@use 'abstracts/variables';
@use 'abstracts/colors';

/* stylelint-disable declaration-no-important */

.mat-radio-outer-circle {
  border: 1px solid #e3e3e3 !important;
}

.mat-radio-inner-circle {
  height: 24px !important;
  left: -2px !important;
  top: -2px !important;
  width: 24px !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: map-get(colors.$color, 'secondary');
}
