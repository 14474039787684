@use 'abstracts/variables';
@use 'abstracts/colors';
@use 'abstracts/units';

html {
  color: map-get(colors.$color, 'text-main-title');
  font-family: 'Ubuntu', sans-serif;
  font-size: $global-font-size * 0.85;

  @include media-breakpoint-up(sm) {
    font-size: $global-font-size;
  }
}

// Text utility classes.
@each $type, $value in variables.$typography {
  .text-#{$type} {
    font-size: map-get($value, 'font-size') !important; // stylelint-disable-line declaration-no-important
    font-weight: map-get($value, 'font-weight') !important; // stylelint-disable-line declaration-no-important
    line-height: map-get($value, 'line-height') !important; // stylelint-disable-line declaration-no-important
  }
}

// Font size utility classes
@each $type, $value in variables.$font-size {
  .font-size-#{$type} {
    font-size: $value !important; // stylelint-disable-line declaration-no-important
    line-height: map-get(variables.$line-height, $type) !important; // stylelint-disable-line declaration-no-important
  }
}

// Font weight utility classes
@each $type, $value in variables.$font-weight {
  .font-weight-#{$type} {
    font-weight: $value !important; // stylelint-disable-line declaration-no-important
  }
}

// Color utility classes
@each $type, $value in colors.$color {
  .color-#{$type} {
    color: $value !important; // stylelint-disable-line declaration-no-important
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// Header utility classes
@each $type, $value in variables.$header {
  #{$type} {
    color: map-get($value, 'color');
    font-size: map-get($value, 'font-size');
    font-weight: map-get($value, 'font-weight');
  }
}

.page-title {
  color: map-get(colors.$color, 'navigation-text');
  font-size: units.rem-calc(24);
  text-transform: uppercase;
}
