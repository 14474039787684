@mixin font($size, $weight, $color: '') {
  @if $color != '' {
    color: $color;
  }

  font-size: $size;
  font-weight: $weight;
}

@mixin crop-edge($size, $background-color) {
  & {
    position: relative;
  }

  &::before {
    background-color: $background-color;
    bottom: 0;
    clip-path: polygon(0 $size, $size 0, 100% 0, 100% 100%, 0 100%);
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
