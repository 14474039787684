@use 'abstracts/variables';
@use 'abstracts/colors';

.list-table {
  --horizontal-cell-padding: 0.5rem;

  .row {
    display: grid;

    // Override Bootstrap's default.
    margin-left: 0;
    margin-right: 0;
  }

  .cell {
    align-items: center;
    display: flex;
    padding-left: var(--horizontal-cell-padding);
    padding-right: var(--horizontal-cell-padding);
  }

  .header {
    .cell {
      color: map-get(colors.$color, 'text-secondary');
      font-size: map-get(variables.$font-size, 'micro');
    }
  }

  .body {
    background-color: #fff;
    border-radius: 2px;

    > .row {
      box-shadow: 0 0 40px 0 rgba(20, 72, 118, 8%);
    }

    .cell {
      font-size: map-get(variables.$font-size, 'small');
      padding-bottom: var(--vertical-cell-padding);
      padding-top: var(--vertical-cell-padding);
      position: relative;

      &:not(:first-child):not(.no-border)::before {
        background-color: rgba(map-get(colors.$color, 'background-05'), 0.12);
        bottom: 0.5rem;
        content: '';
        left: 0;
        position: absolute;
        top: 0.5rem;
        width: 1px;
      }
    }
  }

  .header,
  .body {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
}
